import SpeechSynthesisVoice from './SpeechSynthesisVoice';

export default function () {
  return [
    new SpeechSynthesisVoice({ lang: 'ar-EG', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ar-EG, Hoda)' }),
    new SpeechSynthesisVoice({ lang: 'ar-SA', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ar-SA, Naayf)' }),
    new SpeechSynthesisVoice({ lang: 'bg-BG', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (bg-BG, Ivan)' }),
    new SpeechSynthesisVoice({ lang: 'ca-ES', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ca-ES, HerenaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'cs-CZ', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (cs-CZ, Jakub)' }),
    new SpeechSynthesisVoice({ lang: 'da-DK', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (da-DK, HelleRUS)' }),
    new SpeechSynthesisVoice({ lang: 'de-AT', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (de-AT, Michael)' }),
    new SpeechSynthesisVoice({ lang: 'de-CH', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (de-CH, Karsten)' }),
    new SpeechSynthesisVoice({ lang: 'de-DE', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (de-DE, Hedda)' }),
    new SpeechSynthesisVoice({ lang: 'de-DE', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (de-DE, HeddaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'de-DE', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (de-DE, Stefan, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'el-GR', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (el-GR, Stefanos)' }),
    new SpeechSynthesisVoice({ lang: 'en-AU', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-AU, Catherine)' }),
    new SpeechSynthesisVoice({ lang: 'en-AU', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-AU, HayleyRUS)' }),
    new SpeechSynthesisVoice({ lang: 'en-CA', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-CA, Linda)' }),
    new SpeechSynthesisVoice({ lang: 'en-CA', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-CA, HeatherRUS)' }),
    new SpeechSynthesisVoice({ lang: 'en-GB', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-GB, Susan, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'en-GB', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-GB, HazelRUS)' }),
    new SpeechSynthesisVoice({ lang: 'en-GB', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-GB, George, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'en-IE', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-IE, Sean)' }),
    new SpeechSynthesisVoice({ lang: 'en-IN', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-IN, Heera, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'en-IN', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-IN, PriyaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'en-IN', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-IN, Ravi, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'en-US', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-US, ZiraRUS)' }),
    new SpeechSynthesisVoice({ lang: 'en-US', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-US, JessaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'en-US', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-US, BenjaminRUS)' }),
    new SpeechSynthesisVoice({ lang: 'en-US', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-US, Jessa24kRUS)' }),
    new SpeechSynthesisVoice({ lang: 'en-US', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (en-US, Guy24kRUS)' }),
    new SpeechSynthesisVoice({ lang: 'es-ES', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (es-ES, Laura, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'es-ES', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (es-ES, HelenaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'es-ES', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (es-ES, Pablo, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'es-MX', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (es-MX, HildaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'es-MX', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (es-MX, Raul, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'fi-FI', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (fi-FI, HeidiRUS)' }),
    new SpeechSynthesisVoice({ lang: 'fr-CA', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (fr-CA, Caroline)' }),
    new SpeechSynthesisVoice({ lang: 'fr-CA', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (fr-CA, HarmonieRUS)' }),
    new SpeechSynthesisVoice({ lang: 'fr-CH', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (fr-CH, Guillaume)' }),
    new SpeechSynthesisVoice({ lang: 'fr-FR', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (fr-FR, Julie, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'fr-FR', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (fr-FR, HortenseRUS)' }),
    new SpeechSynthesisVoice({ lang: 'fr-FR', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (fr-FR, Paul, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'he-IL', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (he-IL, Asaf)' }),
    new SpeechSynthesisVoice({ lang: 'hi-IN', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (hi-IN, Kalpana, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'hi-IN', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (hi-IN, Kalpana)' }),
    new SpeechSynthesisVoice({ lang: 'hi-IN', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (hi-IN, Hemant)' }),
    new SpeechSynthesisVoice({ lang: 'hr-HR', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (hr-HR, Matej)' }),
    new SpeechSynthesisVoice({ lang: 'hu-HU', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (hu-HU, Szabolcs)' }),
    new SpeechSynthesisVoice({ lang: 'id-ID', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (id-ID, Andika)' }),
    new SpeechSynthesisVoice({ lang: 'it-IT', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (it-IT, Cosimo, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'it-IT', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (it-IT, LuciaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'ja-JP', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ja-JP, Ayumi, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'ja-JP', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ja-JP, Ichiro, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'ja-JP', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ja-JP, HarukaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'ko-KR', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ko-KR, HeamiRUS)' }),
    new SpeechSynthesisVoice({ lang: 'ms-MY', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ms-MY, Rizwan)' }),
    new SpeechSynthesisVoice({ lang: 'nb-NO', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (nb-NO, HuldaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'nl-NL', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (nl-NL, HannaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'pl-PL', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (pl-PL, PaulinaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'pt-BR', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (pt-BR, HeloisaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'pt-BR', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (pt-BR, Daniel, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'pt-PT', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (pt-PT, HeliaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'ro-RO', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ro-RO, Andrei)' }),
    new SpeechSynthesisVoice({ lang: 'ru-RU', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ru-RU, Irina, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'ru-RU', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ru-RU, Pavel, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'ru-RU', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ru-RU, EkaterinaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'sk-SK', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (sk-SK, Filip)' }),
    new SpeechSynthesisVoice({ lang: 'sl-SI', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (sl-SI, Lado)' }),
    new SpeechSynthesisVoice({ lang: 'sv-SE', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (sv-SE, HedvigRUS)' }),
    new SpeechSynthesisVoice({ lang: 'ta-IN', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (ta-IN, Valluvar)' }),
    new SpeechSynthesisVoice({ lang: 'th-TH', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (th-TH, Pattara)' }),
    new SpeechSynthesisVoice({ lang: 'tr-TR', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (tr-TR, SedaRUS)' }),
    new SpeechSynthesisVoice({ lang: 'vi-VN', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (vi-VN, An)' }),
    new SpeechSynthesisVoice({ lang: 'zh-CN', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-CN, HuihuiRUS)' }),
    new SpeechSynthesisVoice({ lang: 'zh-CN', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-CN, Yaoyao, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'zh-CN', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-CN, Kangkang, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'zh-HK', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-HK, Tracy, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'zh-HK', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-HK, TracyRUS)' }),
    new SpeechSynthesisVoice({ lang: 'zh-HK', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-HK, Danny, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'zh-TW', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-TW, Yating, Apollo)' }),
    new SpeechSynthesisVoice({ lang: 'zh-TW', gender: 'Female', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-TW, HanHanRUS)' }),
    new SpeechSynthesisVoice({ lang: 'zh-TW', gender: 'Male', voiceURI: 'Microsoft Server Speech Text to Speech Voice (zh-TW, Zhiwei, Apollo)' })
  ];
}
